.education {
  @include make-spacer(p, 2rem);

  position: relative;
  border: var(--default-border);
  border-radius: var(--default-border-radius);
  overflow: hidden;
  z-index: $z-index-1;

  &:hover {

    &::before {
      width: 100%;
    }
  }

  &::before {
    @include size(0, 100%);

    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--color-background);
    transition: var(--default-transition);
    z-index: -1;
    content: '';
  }

  &__year {
    @include flex($align-items: center);

    gap: rem(10px);

    i {
      @include make-spacer(mt, rem(-3px));

      font-size: rem(36px);
    }
  }

  &__title {
    @include make-spacer(my, .5rem);
  }
}
