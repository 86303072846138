.section-skills {
  @include flex(center, center, column);

  &__subtitle,
  &__wrapper,
  &__item {
    position: relative;
  }

  &__content {
    display: grid;
    gap: 5rem;
    width: 100%;

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__subtitle {
    @include make-spacer(mb, 1.5rem);
    @include make-spacer(ml, 2rem);

    display: inline-block;
  }

  &__list {
    @include make-spacer(p, 2rem);

    border: var(--default-border);
    border-radius: var(--default-border-radius);
    z-index: $z-index-1;
    overflow: hidden;

    &:hover {

      &::before {
        width: 100%;
      }
    }

    &::before {
      @include size(0, 100%);

      position: absolute;
      top: 0;
      left: 0;
      background-color: var(--color-background);
      transition: var(--default-transition);
      z-index: -1;
      content: '';
    }
  }

  &__toggle {
    display: none;

    &--active {
      display: block;
    }
  }

  &__button {
    @include make-spacer(m, 1rem);

    &--hide {
      display: none;
    }
  }
}
