.section-home {
  @include flex(null, center);

  min-height: 500px;
  height: 100vh;

  &__title,
  &__subtitle,
  &__description,
  &__box {
    position: relative;
  }

  &__content {
    max-width: 80rem;
  }

  &__title {
    @include make-spacer(mb, 2rem);

    display: inline-block;
  }

  &__subtitle {
    @include stroke(0.7px, var(--color-accent), transparent);

    background-image: linear-gradient(var(--color-accent), var(--color-accent));
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    background-position: 0 0;
    animation: homeText 5s linear infinite;
    animation-delay: 2s;
    white-space: nowrap;
    width: 33rem;
    font-size: 3.2rem;

    &::before {
      @include size(0, 100%);

      position: absolute;
      top: 0;
      left: 0;
      border-right: 2px solid var(--color-accent);
      z-index: -1;
      animation: homeTextCursor 5s linear infinite;
      animation-delay: 2s;
      content: '';
    }
  }

  &__description {
    @include make-spacer(mt, 2rem);
    @include make-spacer(mb, 4rem);
  }

  &__social {
    position: absolute;
    bottom: 4rem;
  }

  &__box {
    @include flex(space-between);
    @include size(34.5rem, 5rem);
  }
}
