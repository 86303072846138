.bx {
  font-family: 'Icons';
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
  display: inline-block;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.bxs-moon:before {
  content: "\ee4e"
}

.bxs-sun:before {
  content: "\eebe"
}

.bxs-calendar:before {
  content: "\ed00"
}

.bxl-codepen:before {
  content: "\e91b"
}

.bxl-linkedin:before {
  content: "\e94d"
}

.bxl-github:before {
  content: "\e93a"
}

.bxl-telegram:before {
  content: "\e97b"
}

.bxl-skype:before {
  content: "\e96f"
}

.bx-up-arrow-alt:before {
  content: "\ec5d"
}

.bx-link-external:before {
  content: "\eb3e"
}
