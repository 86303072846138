.u-visible {

  @each $breakpoint in map-keys($grid-breakpoints) {
    &-#{$breakpoint} {
      display: none;

      @include media-breakpoint-up($breakpoint) {
        display: block;
      }
    }
  }
}
