@keyframes homeText {
  0%,
  10%,
  100% {
    background-position: -33rem 0;
  }

  65%,
  85% {
    background-position: 0 0;
  }
}

@keyframes homeTextCursor {
  0%,
  10%,
  100% {
    width: 0
  }

  65%,
  78%,
  85% {
    width: 100%;
    opacity: 1;
  }

  75%,
  81% {
    opacity: 0;
  }
}

@keyframes aboutSpinner {
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes showRight {
  100% {
    width: 0;
  }
}
