.button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: rem(2px) solid var(--color-accent);
  border-radius: .8rem;
  overflow: hidden;
  z-index: $z-index-1;
  transition: .5s;
  min-height: 5rem;
  min-width: 15rem;
  height: 100%;
  font-size: 1.8rem;
  font-weight: var(--typography-medium);
  letter-spacing: .1rem;
  color: var(--color-background);

  &::before {
    @include size(0, 100%);

    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: var(--default-transition);
    content: '';
  }

  &:hover {

    &::before {
      width: 100%;
    }
  }

  &--accent {
    background-color: var(--color-accent);

    &::before {
      background-color: var(--color-background);
    }

    &:hover {
      color: var(--color-accent);
    }
  }

  &--transparent {
    color: var(--color-accent);

    &::before {
      background-color: var(--color-accent);
    }

    &:hover {
      color: var(--color-background);
    }
  }
}
