.toggle {
  @include size(rem(76px), rem(40px));

  display: block;
  position: relative;
  border-radius: rem(40px);
  overflow: hidden;

  &__input {
    @include size(100%);

    position: relative;
    z-index: $z-index-1;
    opacity: 0;
    cursor: pointer;

    &:checked + .toggle {

      &__slide {
        background-color: var(--color-accent);

        &::before {
          transform: translate(rem(35px), -50%);
          background-color: var(--color-white);
        }
      }
    }

    &:checked ~ .toggle {

      &__icon {

        &--right {
          color: var(--color-text);
        }
      }
    }
  }

  &__slide {
    @include absolute-filled;

    transition: var(--default-transition);
    background-color: var(--color-accent);

    &::before {
      @include size(rem(36px));
      @include absolute-center-left(rem(2px));

      border-radius: 50%;
      background-color: var(--color-background);
      transition: var(--default-transition);
      content: '';
    }
  }

  &__icon {
    @include flex(center, center);
    @include size(rem(36px));

    font-size: rem(19px);

    &--left {
      @include absolute-center-left(rem(2px));
    }

    &--right {
      @include absolute-center-right(rem(2px));

      color: var(--color-background-alt);
    }
  }
}
