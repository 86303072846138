.footer {
  @include flex(space-between, center, null, wrap);
  @include make-spacer(py, 2rem);

  @include make-spacer(px, 4%);
  @include make-spacer(px, 9%, lg);

  gap: rem(15px);

  &__text,
  &__link,
  &__button {
    position: relative;
  }

  &__link {
    @include make-spacer(p, .8rem);

    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-accent);
    border: var(--default-border);
    border-radius: var(--default-border-radius);
    overflow: hidden;
    z-index: $z-index-1;
    font-size: 2.4rem;
    color: var(--color-background);

    &::before {
      @include size(0, 100%);

      position: absolute;
      top: 0;
      left: 0;
      background-color: var(--color-background-alt);
      transition: var(--default-transition);
      z-index: -1;
      content: '';
    }

    &:hover {
      color: var(--color-accent);

      &::before {
        width: 100%;
      }
    }
  }
}
