.hamburger {
  @include size(rem(60px));
  @include flex(center, center, column);

  position: relative;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    display: none;
  }

  &__item {
    @include make-spacer(my, rem(5px));
    @include size(100%, rem(3px));

    flex-shrink: 0;
    border-radius: rem(3px);
    background-color: var(--color-text);
    transition: var(--default-transition)
  }

  &--active {

    .hamburger {

      &__item {

        &:nth-child(1) {
          transform: translateY(rem(13px)) rotateZ(45deg);
        }

        &:nth-child(2) {
          transform: translateX(-100%);
          opacity: 0;
        }

        &:nth-child(3) {
          transform: translateY(rem(-12px)) rotateZ(-45deg);
        }
      }
    }
  }
}
