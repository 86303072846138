.social {
  @include flex(space-between);

  &__item {
    @include make-spacer(mr, 2rem);
    @include flex(center, center);
    @include size(rem(80px));

    position: relative;
    background-color: transparent;
    border: var(--default-border);
    border-radius: 50%;
    z-index: $z-index-1;
    overflow: hidden;
    transition: .5s;
    font-size: rem(40px);
    color: var(--color-accent);

    &:before {
      @include size(0, 100%);

      position: absolute;
      top: 0;
      left: 0;
      background-color: var(--color-accent);
      z-index: -1;
      transition: var(--default-transition);
      content: '';
    }

    &:hover {
      color: var(--color-background);

      &:before {
        width: 100%;
      }
    }
  }
}
