.skill {
  @include make-spacer(p, 1rem);

  &__title {
    @include flex(space-between, center);

    gap: rem(15px);
  }

  &__bar {
    @include make-spacer(my, 1rem);
    @include make-spacer(p, .5rem);

    position: relative;
    border-radius: var(--default-border-radius);
    border: var(--default-border);
    overflow: hidden;
    height: 2.5rem;

    span {

      &:first-child {
        @include size(100%);

        display: block;
        border-radius: .3rem;
        background-color: var(--color-accent);
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition: var(--default-transition);
      }

      &:last-child {
        @include size(100%);
      }
    }
  }
}
