html {
  font-size: 50%;

  @include media-breakpoint-up(sm) {
    font-size: 55%;
  }

  @include media-breakpoint-up(lg) {
    font-size: 62.5%;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 72%;
  }
}

body {
  @include scroll;
  @include smooth-font;

  min-width: 375px;
  min-height: 100dvh;
  transition: min-height .3s;
  overflow-x: hidden;
  background-color: var(--color-background);
  color: var(--color-text);
  line-height: var(--line-height);
  font-family: var(--font-family);
  font-weight: var(--typography-light);
}

@include make-all-type($tag-types);

.main {
  position: relative;
}

.u-text-animation {
  @include size(105%);

  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--color-background);
  z-index: $z-index-10;
}


.logo,
.navbar,
.hamburger,
.toggle,
.social {

  .u-text-animation {
    animation: showRight 1s ease forwards;
    animation-delay: calc(.3s * var(--i));
  }
}

.u-section-animation {

  .u-text-animation {
    animation: showRight 1s ease forwards;
    animation-delay: calc(.3s * var(--i));
  }
}

.section {
  @include make-spacer(py, 4rem);
  @include make-spacer(py, 10rem, lg);

  @include make-spacer(px, 4%);
  @include make-spacer(px, 9%, lg);

  position: relative;
  min-height: 100vh;
  transition: min-height .3s;
  width: 100%;

  .u-text-animation {
    background-color: var(--color-background);
  }

  &--background-alt {
    background-color: var(--color-background-alt);

    .u-text-animation {
      background-color: var(--color-background-alt);
    }
  }

  &__title {
    @include make-spacer(mb, 3rem);

    position: relative;
  }
}
