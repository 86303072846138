.section-about {
  @include flex(center, center, column);

  gap: 2rem;

  &__subtitle,
  &__description,
  &__box {
    position: relative;
  }

  &__picture {
    @include flex(center, center);
    @include size(25rem);

    position: relative;
    border-radius: 50%;
  }

  &__img {
    @include size(90%);

    border-radius: 50%;
    border: var(--default-border);
    overflow-y: hidden;

    img {
      @include size(100%);

      display: block;
      object-fit: cover;
    }
  }

  &__spin {
    @include size(100%);

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0);
    border-radius: 50%;
    border-top: .2rem solid var(--color-background-alt);
    border-left: var(--default-border);
    border-right: var(--default-border);
    border-bottom: .2rem solid var(--color-background-alt);
    animation: aboutSpinner 8s linear infinite;
  }

  &__description {
    @include make-spacer(mt, 2rem);
    @include make-spacer(mb, 4rem);

    max-width: 100rem;
  }

  &__box {
    @include size(20rem, 5rem);
  }

  &__link {
    width: 20rem;
  }
}
