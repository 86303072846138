:root {
  --font-size: 1rem;
  --font-family: 'Poppins', sans-serif;

  --line-height: rem(25px);
  --typography-light: 300;
  --typography-medium: 500;
  --typography-bold: 700;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include make-spacer(my, 0);

  font-weight: var(--typography-bold);
}



//
// Headings
//

$fs-h1: 5.4rem, null, 1.3;
$fs-h2: 5rem, null, 1.5;
$fs-h3: 3.2rem, null, 1.3;
$fs-h4: 2.5rem, null, 1.2;
$fs-h5: 2.2rem, null, 1.1;

$tag-types: (
  'h1': (
    null: ($fs-h1),
  ),
  'h2': (
    null: ($fs-h2),
  ),
  'h3': (
    null: ($fs-h3),
  ),
  'h4': (
    null: ($fs-h4),
  ),
  'h5': (
    null: ($fs-h5),
  ),
  'p': (
    null: (1.6rem, 400, 1.5)
  )
);
