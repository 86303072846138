.header {
  @include flex(space-between, center);
  @include make-spacer(py, 2rem);

  @include make-spacer(px, 4%);
  @include make-spacer(px, 9%, lg);

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: $z-index-11;
  transition: var(--default-transition);
  background-color: var(--color-background);

  @include media-breakpoint-up(sm) {
    background-color: transparent;
  }

  &--sticky {
    background-color: var(--color-background);
    box-shadow: 0 0 rem(15px) 0 rgba(0, 0, 0, 0.5);
  }
}
