body[data-theme="dark"] {
  --color-accent: #00abf0;
  --color-background: #081b29;
  --color-background-alt: #112e42;
  --color-text: #ededed;
}

body[data-theme="light"] {
  --color-accent: #FF6600;
  --color-background: #f7e4d8;
  --color-background-alt: #eed1bd;
  --color-text: #333333;
}

body {
  --color-white: #fff;
  --color-black: #000;
  --default-border: .2rem solid var(--color-accent);
}
