@mixin scroll {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-corner {
    background-color: var(--color-background-alt);
  }

  &::-webkit-scrollbar-thumb {
    border: 2px solid var(--color-accent);
    border-radius: 3px;
    background-clip: content-box;
    background-color: var(--color-accent);
  }

  &::-webkit-scrollbar-track {
    border: 1px solid var(--color-background-alt);
    background-color: var(--color-background-alt);
  }
}
