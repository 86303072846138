.navbar {
  @include make-spacer(px, 4%);
  @include make-spacer(py, 1rem);
  @include flex($direction: column);

  position: absolute;
  top: 100%;
  left: -100%;
  background-color: var(--color-accent);
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .2);
  transition: .25s ease;
  transition-delay: .25s;
  z-index: $z-index-1;
  width: 100%;

  &::before {
    @include size(100%);

    position: absolute;
    top: 0;
    left: -100%;
    z-index: -1;
    background-color: var(--color-background);
    transition: .25s ease;
    transition-delay: 0s;
    content: '';

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  @include media-breakpoint-up(md) {
    align-items: center;
    flex-direction: row;
    position: relative;
    top: unset;
    left: unset;
    background-color: transparent;
    box-shadow: unset;
    transition: unset;
    width: unset;
  }

  &--active {
    left: 0;
    transition-delay: 0s;

    &::before {
      left: 0;
      transition-delay: .25s;
    }

    .navbar {

      &__item {
        transition: .25s ease;
        transition-delay: 0s;
        transform: translateX(0);
      }
    }
  }

  &__item {
    @include make-spacer(my, 1.5rem);
    @include make-spacer(ml, 3.5rem, md);
    @include make-spacer(my, 0, md);

    transition: var(--default-transition);
    font-weight: var(--typography-medium);
    font-size: 2rem;
    transition-delay: 0s;
    transform: translateX(-20rem);

    @include media-breakpoint-up(md) {
      font-size: 1.7rem;
      transform: translateX(0);
    }

    &--active,
    &:hover {
      color: var(--color-accent);
    }
  }
}
