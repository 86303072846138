.project {
  position: relative;
  border-radius: var(--default-border-radius);
  overflow: hidden;

  &:hover {

    .project {

      &__image {
        transform: scale(1.2);
      }

      &__content {
        opacity: 1;
        z-index: $z-index-1;
      }
    }
  }

  &__image {
    @include size(100%);

    display: block;
    transition: var(--default-transition);

    .img {
      @include size(100%);

      display: block;
      object-fit: cover;
    }
  }

  &__content {
    @include make-spacer(px, 4rem);
    @include flex(center, center, column);
    @include size(100%);

    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.9), var(--color-accent));
    opacity: 0;
    transition: all .3s;
    z-index: -1
  }

  &__title {
    @include make-spacer(mb, 1rem);

    font-size: 2.5rem;
    color: var(--color-white);
  }

  &__link {
    @include size(5rem);
    @include flex(center, center);

    border-radius: 50%;
    background-color: var(--color-white);
    font-size: 2rem;
    color: var(--color-accent);

    &:hover {
      background-color: var(--color-background);
    }
  }
}
