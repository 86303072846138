.section-projects {

  &__content {
    display: grid;
    grid-template-columns: repeat(auto-fill, 320px);
    justify-content: center;
    gap: 2.5rem;
  }

  &__item {
    position: relative;
  }
}
