.u-hidden {
  display: none !important;

  @each $breakpoint in map-keys($grid-breakpoints) {
    &-#{$breakpoint} {
      display: none;

      @include media-breakpoint-down($breakpoint) {
        display: none !important;
      }
    }
  }
}
