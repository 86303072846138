.section-journey {
  @include flex(center, center, column);

  &__subtitle,
  &__line {
    position: relative;
  }

  &__content {
    @include make-spacer(pb, 5rem);

    display: grid;
    gap: 5rem;

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__subtitle {
    @include make-spacer(mb, 1.5rem);
    @include make-spacer(ml, 2rem);

    display: inline-block;
  }

  &__line {
    border-left: var(--default-border);
  }

  &__item {
    @include make-spacer(pl, 2rem);
    @include make-spacer(mb, 2rem);

    position: relative;

    &::after,
    &::before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: var(--color-accent);
      content: '';
    }

    &::before {
      @include size(2rem);

      left: -1.1rem;
      border-radius: 50%;
    }

    &::after {
      @include size(2rem, rem(2px));

      left: 0;
    }
  }
}
