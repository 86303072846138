.section-contact {
  @include flex(center, center, column);

  &__form,
  &__row {
    position: relative;
  }

  &__input,
  &__textarea {
    @include make-spacer(p, 1.5rem);
    @include size(100%);

    font-size: 1.6rem;
    color: var(--color-text);
    background-color: transparent;
    border: 0;

    &::placeholder {
      color: inherit;
    }

    &:valid,
    &:focus {
      outline: 0;

      & ~ .section-contact {

        &__focus {
          width: 100%;
        }
      }
    }
  }

  &__form,
  &__button {
    @include make-spacer(mx, auto);
  }

  &__form,
  &__alert {
    max-width: 70rem;
    width: 100%;
  }

  &__alert {
    @include make-spacer(mb, 1.6rem);
    @include make-spacer(p, 1.5rem);

    display: none;
    opacity: 0;
    border-radius: var(--default-border-radius);
    background-color: var(--color-accent);
    text-align: center;

    &--active {
      display: block;
      animation: fadeIn 0.5s ease-in-out forwards;
    }
  }

  &__form {
    display: grid;
    row-gap: 1.6rem;
  }

  &__row {
    display: grid;
    gap: 1.6rem;

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(2, 1fr);
    }

    &--full {
      grid-template-columns: 1fr;
    }
  }

  &__box {
    position: relative;
    border-radius: var(--default-border-radius);
    border: var(--default-border);
    overflow: hidden;
    z-index: $z-index-1;
  }

  &__focus {
    @include size(0, 100%);

    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--color-background);
    z-index: -1;
    transition: var(--default-transition);
  }

  &__textarea {
    resize: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
