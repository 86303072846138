@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Icons';
  src: url('../fonts/BoxIcon/BoxIcon.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
