@use "sass:math";

$spacings: (15);
$directions: ('mt', 'mr', 'mb', 'ml', 'mx', 'my', 'pt', 'pr', 'pb', 'pl', 'px', 'py');

@mixin set-spacing($spacing, $direction, $breakpoint: null) {
  @if ($breakpoint != null and $breakpoint != xs) {

    .u-#{$direction}-#{$spacing}-#{$breakpoint} {
      @include make-spacer($direction, #{$spacing}px, $breakpoint);
    }
  }

  @else {

    .u-#{$direction}-#{$spacing} {
      @include make-spacer($direction, #{$spacing}px);
    }
  }
}

@each $spacing in $spacings {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @each $direction in $directions {
      @include set-spacing($spacing, $direction, $breakpoint);
    }
  }
}

.u-mx-auto {
  margin-right: auto;
  margin-left: auto;
}
